import { CloseIcon } from '@/v1/Icons'

interface Props {
  onClose: () => void
}
export const CloseIconButton = ({ onClose }: Props) => {
  return (
    <div className="text-end">
      <button onClick={onClose}>
        <CloseIcon size={20} className="fill-grey-500" />
      </button>
    </div>
  )
}
