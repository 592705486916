import clsx from 'clsx'
import Thyroid from '@/assets/icons/thyroid.svg'
import { SvgProps } from '@/types'

const ThyroidIcon = ({
  alt = 'thyroid',
  className = '',
  width = 20,
  height = 20,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <Thyroid
        width={width}
        height={height}
        className={clsx('fill-gray-100', className)}
        {...rest}
      />
    </i>
  )
}

export default ThyroidIcon
