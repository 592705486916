import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import type { CancerType } from '@/types/recommend'
import { getSessionStorage, setSessionStorage } from '@/utils'

export const MAIN_KEY = '__main__'

export interface Main {
  hasMainViewed: boolean
  cancerType: CancerType | undefined
}

export const INITIAL_MAIN: Main = {
  hasMainViewed: false,
  cancerType: undefined,
}

const storage = createJSONStorage<Main>(() => sessionStorage)

export const mainAtom = atomWithStorage(MAIN_KEY, INITIAL_MAIN, {
  ...storage,
  getItem: (key, initialValue) => {
    return getSessionStorage(key, initialValue)
  },
  setItem: (key, newValue) => {
    return setSessionStorage(key, newValue)
  },
})
