import clsx from 'clsx'
import SpeechBubble from '@/assets/icons/speechBubble.svg'
import { SvgProps } from '@/types'

const SpeechBubbleIcon = ({
  alt = 'Kakao',
  className = '',
  width = 24,
  height = 24,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <SpeechBubble
        width={width}
        height={height}
        className={clsx(`fill-brown-kakao`, className)}
        {...rest}
      />
    </i>
  )
}

export default SpeechBubbleIcon
