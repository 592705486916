'use client'

import { useEffect, useRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { S3_ICON_URL } from '@/config'
import { IOS_APP_STORE, MOBILE_EVENTS } from '@/consts'
import { getPlatformFromCookie, isWebview, postMessageToApp } from '@/utils'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  text: string
  size?: 'lg' | 'md'
  className?: string
}

/**
 * iOS 앱에서만 노출한다.
 * @param text 버튼 텍스트
 * @param size 버튼 크기
 */
const AppleLoginButton = ({ text, size = 'md', ...rest }: Props) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const platform = getPlatformFromCookie()
  const isWeb = !isWebview()

  useEffect(() => {
    const handleVisibilityChange = () => {
      clearTimeout(timerRef.current)
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // 안드로이드 웹뷰에서는 애플 로그인 버튼을 노출하지 않는다.
  if (platform === 'android') return null

  const handleClick = () => {
    if (isWeb) {
      // 먼저 앱 실행 시도
      window.location.href = 'lunitcare://'

      // 앱이 실행되지 않을 경우, 타임아웃 후 앱스토어로 리디렉션
      timerRef.current = setTimeout(() => {
        window.location.href = IOS_APP_STORE
      }, 2000)

      return
    }
    // 안드로이드 앱에서는 이 버튼이 노출되지 않는다. iOS 앱에서는 애플 로그인 이벤트를 전달한다.
    postMessageToApp(MOBILE_EVENTS.APPLE_LOGIN)
  }

  return (
    <button
      type="button"
      className={clsx(
        'flex',
        'justify-center',
        'items-center',
        'w-full',
        'rounded-lg',
        'bg-white',
        'text-black',
        'cursor-pointer',
        'border',
        'border-grey-300',
        'h-11',
        { 'py-2.5': size === 'md' },
        { 'py-[14.5px]': size === 'lg' },
      )}
      {...rest}
      onClick={handleClick}
    >
      <Image
        width={20}
        height={20}
        src={`${S3_ICON_URL}/apple-symbol.svg`}
        alt=""
        className="mr-2"
      />
      <span className={size === 'lg' ? 'prose-p1' : 'prose-p2'}>
        <b>{text}</b>
      </span>
    </button>
  )
}

export default AppleLoginButton
