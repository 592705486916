import {
  AuthProvider,
  ENCODE_STATE_QUERY_ERROR,
  PROVIDER_SEPARATOR,
} from '@/consts'
import { normalizeURL } from './normalizeURL'

// 다음 쿼리 파라미터(&)가 나오기 전까지 파싱
const STATE_PARAMETER_REGEX = /state=([^&]*)/

/**
 * 로그인 시 전달된 state 쿼리 파라미터를 파싱하여 redirect url과 provider 정보를 반환한다.
 * 이때 redirect url은 유효한 형태로 반환한다.
 * @returns provider : 로그인 수단
 * @returns redirectPath : 로그인 이후 리다이렉트할 url
 * @example
 * // returns { provider: 'NAVER', redirectPath: '/users/me' }
 * parseSearchParams() // 현재 쿼리스트링이 '?code=abc&state=NAVER::%2Fusers%2Fme'인 경우
 * */
const parseSearchParams = () => {
  if (typeof window === 'undefined') {
    return { provider: undefined, redirectPath: undefined }
  }
  const { hash, search } = window.location
  // Router의 query로 얻은 state 값을 사용하면 프래그먼트(#)나 & 연산자가 포함되지 않으므로
  // 아예 쿼리스트링 기호 (?) 이후에 있는 부분을 문자열로 변환해서 사용한다.
  const match = search.toString().match(STATE_PARAMETER_REGEX)
  const stateQuery = match ? match[1] : ''

  // 카카오의 경우에는 state 쿼리 파라미터에 들어갈 때 한 번 더 인코딩이 되므로, 두 번 디코딩해서 사용한다.
  // hash는, 동일한 이메일 계정 에러가 발생하여 다시 로그인 시도 시 그대로 사용한다.
  const decodedState =
    decodeURIComponent(decodeURIComponent(`${stateQuery}`)) + hash

  return {
    provider: decodedState.split(PROVIDER_SEPARATOR)[0] as AuthProvider,
    redirectPath: normalizeURL(
      decodedState.split(PROVIDER_SEPARATOR)[1] ?? '/',
    ),
  }
}

/** redirectPath와 provider 정보를 하나의 문자열로 합쳐서 반환한다.
 * 이때 redirectPath는 인코딩한 값으로 반환한다.
 * @param provider 로그인 수단
 * @param redirectPath 로그인 이후 리다이렉트할 url
 * @returns redirectPath와 provider 정보가 합쳐진 문자열
 * @returns url에 구분자 기호(::)가 포함된 경우 Error를 리턴한다. 이 경우 try, catch문을 통한 에러핸들링이 필요
 * @example
 * // returns 'KAKAO::%2Fusers%2Fme'
 * encodeStateQuery(provider: 'KAKAO', redirectPath: '/users/me')
 *  * // returns 'NAVER::%2Fusers%2Fme'
 * encodeStateQuery(provider: 'NAVER', redirectPath: '/users/me')
 */
const encodeStateQuery = (provider: AuthProvider, redirectPath: string) => {
  if (redirectPath.includes(PROVIDER_SEPARATOR))
    throw new Error(ENCODE_STATE_QUERY_ERROR)

  const encodedRedirectPath = encodeURIComponent(redirectPath)

  return `${provider}${PROVIDER_SEPARATOR}${encodedRedirectPath}`
}

export { parseSearchParams, encodeStateQuery }
