import { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import BottomSheet from '@/v1/Modal/BottomSheet'
import type { BottomSheetHeader } from '@/v1/Modal/BottomSheet/Header'

interface BottomSheetProps {
  header?: BottomSheetHeader
  content?: ReactNode
  /**
   * BottomSheet의 높이를 고정할지 여부를 결정합니다.
   * @default false 기본적으로는 높이가 유동적으로 변함
   */
  fullHeight?: boolean
}

const BottomSheetModal = NiceModal.create(
  ({ header, content, fullHeight }: BottomSheetProps) => {
    const modal = useModal()

    const handleClose = () => {
      modal.hide()
    }

    return (
      <BottomSheet
        header={header}
        content={content}
        opened={modal.visible}
        onClose={handleClose}
        fullHeight={fullHeight}
      />
    )
  },
)

export default BottomSheetModal
