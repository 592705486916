import clsx from 'clsx'
import Query from '@/assets/icons/query.svg'
import { SvgProps } from '@/types'

const QueryIcon = ({
  alt = 'query',
  className = '',
  width = 20,
  height = 20,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <Query
        width={width}
        height={height}
        className={clsx('fill-gray-100', className)}
        {...rest}
      />
    </i>
  )
}

export default QueryIcon
