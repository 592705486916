import { z } from 'zod'

export const schema400Response = z.record(z.array(z.string()))
export const schemaErrorResponse = z
  .object({
    detail: z.string(),
  })
  .catchall(z.string())

// TODO: z.coerce.date()를 사용하여 Date 타입으로 변환할 수 있다.
export const maintenanceErrorResponse = z.object({
  maintenanceStart: z.string(),
  maintenanceEnd: z.string(),
})

type MaintenanceResponse = z.infer<typeof maintenanceErrorResponse>
export type ErrorMessage = string | Record<string, string[]>

/**
 * Represents an error that can occur during static props generation.
 * @property {number} [status] - The HTTP status code of the error.
 * @property {MaintenanceResponse} [response] - The maintenance-related error response.
 */
export type HandleStaticPropsError = {
  status?: number
  response?: MaintenanceResponse
}
