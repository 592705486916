import { useEffect } from 'react'

/**
 * 모달이 떠있을 때 아래 body가 스크롤되지 않도록 방지한다.
 * @param isModalOpen 모달 열림 상태
 */
const useToggleBodyScroll = (isModalOpen: boolean) => {
  useEffect(() => {
    const bodyStyle = document.body.style
    if (isModalOpen) {
      bodyStyle.overflow = 'hidden'
      // isModalOpen이 true이면 body의 스크롤 체이닝을 막는다.
      // 모달 뒤에서 이중 스크롤 방지
      bodyStyle.overscrollBehaviorY = 'contain'
    }

    return () => {
      if (isModalOpen) {
        bodyStyle.overflow = 'auto'
        bodyStyle.overscrollBehaviorY = 'auto'
      }
    }
  }, [isModalOpen])
}

export default useToggleBodyScroll
