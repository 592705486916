import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { getSessionStorage, setSessionStorage } from '@/utils'

enum Filter {
  cancer = 'cancer',
  cancer_stage = 'cancer_stage',
  therapy_phase = 'therapy_phase',
  emesis_risk = 'emesis_risk',
  keyword = 'keyword',
}

export type RegimensFilter = {
  [key in Exclude<Filter, 'keyword'>]: string[]
} & { [Filter.keyword]: string }

export const INITIAL_REGIMENS_FILTER: RegimensFilter = {
  [Filter.cancer]: [],
  [Filter.cancer_stage]: [],
  [Filter.therapy_phase]: [],
  [Filter.emesis_risk]: [],
  [Filter.keyword]: '',
}
const storage = createJSONStorage<RegimensFilter>(() => sessionStorage)

const validKeys: (keyof RegimensFilter)[] = [
  Filter.cancer,
  Filter.cancer_stage,
  Filter.emesis_risk,
  Filter.therapy_phase,
  Filter.keyword,
]

const filterRegimensQuery = (query: any): RegimensFilter => {
  const filteredQuery: Partial<RegimensFilter> = {}

  for (const key in query) {
    if (validKeys.includes(key as keyof RegimensFilter)) {
      filteredQuery[key as keyof RegimensFilter] = query[key]
    }
  }
  return filteredQuery as RegimensFilter
}

/**
 * 항암 정보 리스트 필터
 */
export const regimensFilterAtom = atomWithStorage(
  '__regimensFilter__',
  INITIAL_REGIMENS_FILTER,
  {
    ...storage,
    getItem: (key, initialValue) => {
      return getSessionStorage(key, initialValue)
    },
    setItem: (key, newValue) => {
      return setSessionStorage(key, filterRegimensQuery(newValue))
    },
  },
)
