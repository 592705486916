import { twMerge } from 'tailwind-merge'
import FilterFilled from '@/assets/icons/v1/filterFilled.svg'
import type { IconProps } from '@/v1/Icons/types'

const FilterFilledIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <FilterFilled
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default FilterFilledIcon
