import { twMerge } from 'tailwind-merge'
import PillFilled from '@/assets/icons/v1/pillFilled.svg'
import type { IconProps } from '@/v1/Icons/types'

const PillFilledIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <PillFilled
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default PillFilledIcon
