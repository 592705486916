import { PropsWithChildren, useEffect } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { FullPageModalHeaderProps } from '@/v1/Header/FullPageModalHeader'
import FullScreen from '@/v1/Modal/FullScreen'

interface FullScreenModalProps {
  closeable?: boolean
  onBack?: () => void
  // 손수 닫기 기능을 처리해야 하면 true로 설정
  manualClose?: boolean
  // 컨텐츠 영역 스크롤 여부. 기본적으로는 스크롤 가능하도록 설정
  scrollable?: boolean
}

/**
 * 전체 화면 모달
 * @param title 모달 제목
 * @param closeable 오버레이 클릭 시 닫으려면 true로 설정
 * @param close 닫기 버튼 표시 여부
 * @param onClose 닫기 버튼 클릭 시 실행할 함수
 * @param onBack 뒤로 가기 버튼 노출 및 클릭 시 실행할 함수
 * @param gaCloseSelector 닫기 버튼 클릭 시 GA 이벤트 전송 시 사용할 셀렉터
 */
const FullScreenModal = NiceModal.create(
  ({
    title,
    closeable = false,
    close = true,
    scrollable = true,
    onClose,
    onBack,
    gaCloseSelector,
    children,
  }: PropsWithChildren<FullScreenModalProps & FullPageModalHeaderProps>) => {
    const modal = useModal()

    const handleClose = () => {
      onClose?.()
      modal.hide()
    }

    useEffect(() => {
      if (modal.visible) return
      modal.resolveHide()
    }, [modal])

    return (
      <FullScreen
        title={title}
        opened={modal.visible}
        closeable={closeable}
        scrollable={scrollable}
        onClose={handleClose}
        onBack={onBack}
        gaCloseSelector={gaCloseSelector}
        close={close}
      >
        {children}
      </FullScreen>
    )
  },
)

export default FullScreenModal
