import KakaoStory from '@/assets/icons/v1/kakaoStory.svg'
import type { IconProps } from '@/v1/Icons/types'

const KakaoStoryIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <KakaoStory height={size} className={className} {...restProps} />
}

export default KakaoStoryIcon
