import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { getSessionStorage, setSessionStorage } from '@/utils'

const APP_BANNER_KEY = '__app_banner__'

const INITIAL_IS_APP_BANNER_OPEN: boolean = true

const storage = createJSONStorage<boolean>(() => sessionStorage)

export const appBannerAtom = atomWithStorage(
  APP_BANNER_KEY,
  INITIAL_IS_APP_BANNER_OPEN,
  {
    ...storage,
    getItem: (key, initialValue) => {
      return getSessionStorage(key, initialValue)
    },
    setItem: (key, newValue) => {
      return setSessionStorage(key, newValue)
    },
  },
)
