import { twMerge } from 'tailwind-merge'
import Indeterminate from '@/assets/icons/v1/indeterminate.svg'
import type { IconProps } from '@/v1/Icons/types'

const IndeterminateIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <Indeterminate
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default IndeterminateIcon
