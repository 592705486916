import { ReactNode } from 'react'
import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'

interface Props {
  id?: string
  gaSelector?: string
  size?: 'xs' | 'small' | 'medium'
  title?: ReactNode
  titleAlign?: 'left' | 'center'
  onClose?: () => void
  leftIcon?: ReactNode
  onLeftIconClick?: () => void
  closeGaSelector?: string
}

export type BottomSheetHeader = {
  [K in keyof Props]: Props[K]
} & {}

const ALIGN_CONFIG = {
  left: 'text-left',
  center: 'text-center',
}

const Header = ({
  id = '',
  gaSelector = '',
  size = 'small',
  title = '',
  titleAlign = 'left',
  onClose,
  leftIcon,
  onLeftIconClick,
  closeGaSelector = '',
}: Props) => {
  return (
    <div
      id={id}
      data-ga={gaSelector}
      className={clsx(
        'flex gap-3',
        'rounded-t-[16px]',
        'bg-white',
        'supports-sticky:sticky top-0 left-0 right-0',
        {
          'px-md pt-4 pb-1': size === 'xs',
        },
        {
          'px-6 pt-6 pb-4': size !== 'xs',
        },
      )}
    >
      {(size === 'small' || titleAlign === 'center') && (
        <div className="w-5 h-5 mt-1 pointer-events-none">
          <button onClick={onLeftIconClick}>{leftIcon}</button>
        </div>
      )}

      <div
        className={clsx(
          'flex-1',
          'pointer-events-none',
          `${size === 'medium' && ALIGN_CONFIG[titleAlign]}`,
          `${size === 'small' && ALIGN_CONFIG['center']}`,
        )}
      >
        <h4 className={size === 'medium' ? 'prose-h5' : 'prose-h6'}>{title}</h4>
      </div>

      <button
        type="button"
        className="inline-block"
        data-ga={closeGaSelector}
        onClick={onClose}
      >
        <MaterialSymbol name="close" />
      </button>
    </div>
  )
}

export default Header
