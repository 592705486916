import { isHTTPError } from '@/utils/httpClient/normalizeError'
import getErrorResponse from './getErrorResponse'
import { maintenanceErrorResponse } from './types'

export const handleStaticPropsError = async (error: unknown) => {
  if (!isHTTPError(error)) {
    return {
      props: {
        data: null,
        error: {
          status: 404,
        },
      },
    }
  }
  const response =
    (await getErrorResponse(error.response.status, error.response)) ?? ''

  if (error.response.status === 404) {
    return {
      props: {
        data: null,
        error: {
          status: 404,
        },
      },
    }
  }

  if (error.response.status === 503) {
    // 긴급에러이면 errorResponse 값을 넘긴다
    const errorResponse = maintenanceErrorResponse.safeParse(response)
    if (errorResponse.success)
      return {
        props: {
          data: null,
          error: {
            status: 503,
            response: errorResponse.data,
          },
        },
      }
    // 일반 503 에러이면 그냥 500 페이지로 이동한다.
    return {
      props: {
        data: null,
        error: {
          status: 500,
        },
      },
    }
  }

  return {
    props: {
      data: null,
      error: {
        status: 500,
      },
    },
  }
}
