import { twMerge } from 'tailwind-merge'
import Kakao from '@/assets/icons/v1/kakao.svg'
import type { IconProps } from '@/v1/Icons/types'

const KakaoIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Kakao
      width={size}
      height={size}
      className={twMerge('fill-kakao-brown', className)}
      {...restProps}
    />
  )
}

export default KakaoIcon
