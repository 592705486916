import KakaoTalk from '@/assets/icons/v1/kakaoTalk.svg'
import type { IconProps } from '@/v1/Icons/types'

const KakaoTalkIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <KakaoTalk height={size} className={className} {...restProps} />
}

export default KakaoTalkIcon
