import clsx from 'clsx'
import Documents from '@/assets/icons/documents.svg'
import { SvgProps } from '@/types'

const DocumentsIcon = ({
  alt = 'documents',
  className = '',
  width = 20,
  height = 20,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <Documents
        width={width}
        height={height}
        className={clsx('fill-gray-100', className)}
        {...rest}
      />
    </i>
  )
}

export default DocumentsIcon
