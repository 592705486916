import { atom } from 'jotai'

export type AIQuestion = {
  state: 'default' | 'loading' | 'done'
  questions: string[]
}

export const INITIAL_AI_QUESTION: AIQuestion = {
  state: 'default',
  questions: [],
}

// AI 추천 질문은 get 요청이 아니라서 swr를 활용할 수 없다.
// post 요청의 응답 값을 상태로 관리하기 위해 atom을 사용한다.
export const aiQuestionAtom = atom<AIQuestion>(INITIAL_AI_QUESTION)
