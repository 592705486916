'use client'

import { PropsWithChildren } from 'react'
import { use100vh } from 'react-div-100vh'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { HEADER_HEIGHT } from '@/consts'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import { noop } from '@/utils'
import {
  FullPageModalHeader,
  FullPageModalHeaderProps,
} from '@/v1/Header/FullPageModalHeader'
import Modal from '@/v1/Modal/index'
import Overlay from '@/v1/Modal/Overlay'
import { fullScreenStyle, modalLayoutStyle } from '@/v1/Modal/styles'

interface FullScreenProps {
  opened: boolean
  closeable: boolean
  scrollable?: boolean
}

const FullScreen = ({
  opened: isModalOpen,
  closeable,
  scrollable = true,
  title,
  gaBackSelector = '',
  gaCloseSelector = '',
  close,
  onClose = noop,
  onBack,
  children,
}: PropsWithChildren<FullScreenProps & FullPageModalHeaderProps>) => {
  const getHeight = use100vh()
  useToggleBodyScroll(isModalOpen)

  if (!isModalOpen) return null

  return (
    <Modal>
      <Overlay closeable={closeable} onClose={onClose} />
      <div
        className={clsx(twMerge(modalLayoutStyle), fullScreenStyle)}
        role="dialog"
        aria-label={title}
      >
        <FullPageModalHeader
          title={title}
          close={close}
          onClose={onClose}
          onBack={onBack}
          back={onBack !== undefined}
          gaBackSelector={gaBackSelector}
          gaCloseSelector={gaCloseSelector}
        />
        <div
          className={clsx('relative', 'w-full', 'overscroll-contain', {
            'overflow-y-auto': scrollable,
            'overflow-hidden': !scrollable,
          })}
          style={{
            height: getHeight
              ? getHeight - HEADER_HEIGHT
              : `calc(100vh_-_${HEADER_HEIGHT}px)`,
          }}
        >
          {children}
          {/* ios 키보드가 올라올 때 body가 스크롤되는 현상이 있다.
          document가 키보드 뒤의 가상영역으로 내려가지 않고 document 내부를 scrollable 하게 만든다. */}
          <div className="absolute inset-0 w-px h-[calc(100%_+_1px)]" />
        </div>
      </div>
    </Modal>
  )
}

export default FullScreen
