import TwitterCircle from '@/assets/icons/v1/twitterCircle.svg'
import type { IconProps } from '@/v1/Icons/types'

const TwitterCircleIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <TwitterCircle height={size} className={className} {...restProps} />
}

export default TwitterCircleIcon
