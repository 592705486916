import dayjs from 'dayjs'
import { Divider } from '@/components/Divider'
import MaterialSymbol from '@/v1/MaterialSymbol'

// 배포 시간 및 날짜 (type A를 노출 시작할 시점)
const START_DATE = '2024-05-13T13:40:00'

const CheckIcon = () => {
  return (
    <MaterialSymbol
      size={20}
      name="check_circle_fill"
      className="fill-aqua-500 mr-1.5"
    />
  )
}

const ContentTypeA = () => {
  return (
    <div className="text-center">
      <p className="text-grey-600 font-bold prose-p3">
        사용자의 진솔한 후기 ✍🏻
      </p>
      <h2 className="font-bold mt-2.5 prose-h5">
        “암 때문에 고민이 많았는데
        <br /> 여기 정보가 가장 믿음이 가네요”
      </h2>
      <Divider className="border-grey-100 my-md" />
      <p className="text-grey-800 mb-5">
        3초 만에 로그인하고 <br />
        <strong>암 전문가의 도움</strong>을 받으세요.
      </p>
    </div>
  )
}

const ContentTypeB = () => {
  return (
    <div className="flex flex-col items-center mb-8">
      <h2 className="prose-h4">
        3초 만에 로그인하고 <br />
        모든 암 정보를 무료로
      </h2>
      <ul className="mt-md text-grey-800">
        <li className="flex items-center mb-2.5">
          <CheckIcon />
          <span className="prose-p1">병원 밖에서 1:1 의료 상담</span>
        </li>
        <li className="flex items-center">
          <CheckIcon />
          <span className="prose-p1">의료진이 검증한 맞춤 정보</span>
        </li>
      </ul>
    </div>
  )
}

export const BottomSheetContent = () => {
  const currentDate = dayjs()
  const startDate = dayjs(START_DATE)

  // 한 달 후에는 type B를 노출 (현재 시간과 비교)
  const showTypeB = startDate.add(1, 'month').isBefore(currentDate)

  if (showTypeB) return <ContentTypeB />
  return <ContentTypeA />
}
