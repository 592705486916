import clsx from 'clsx'
import NaverSymbol from '@/assets/icons/naverSymbol.svg'
import { SvgProps } from '@/types'

const NaverSymbolIcon = ({
  alt = 'Naver',
  className = '',
  width = 24,
  height = 24,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <NaverSymbol
        width={width}
        height={height}
        className={clsx(`fill-white`, className)}
        {...rest}
      />
    </i>
  )
}

export default NaverSymbolIcon
