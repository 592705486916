import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import type { OnboardingForm } from '@/hooks/useMyOnboarding'
import { getSessionStorage, setSessionStorage } from '@/utils'

export const HEALTH_INFO__KEY = '__health_info__'

const storage = createJSONStorage<OnboardingForm | null>(() => sessionStorage)

export const healthInfoAtom = atomWithStorage(HEALTH_INFO__KEY, null, {
  ...storage,
  getItem: (key, initialValue) => {
    return getSessionStorage(key, initialValue)
  },
  setItem: (key, newValue) => {
    return setSessionStorage(key, newValue)
  },
})
