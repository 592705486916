import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { getSessionStorage, setSessionStorage } from '@/utils'

type ViewedBoardIds = string[]

const VIEWED_BOARD_IDS = '__viewedBoardIds__'
const INITIAL_VIEWED_BOARD_IDS: ViewedBoardIds = []
const storage = createJSONStorage<ViewedBoardIds>(() => sessionStorage)

export const viewedBoardIdsAtom = atomWithStorage(
  VIEWED_BOARD_IDS,
  INITIAL_VIEWED_BOARD_IDS,
  {
    ...storage,
    getItem: (key, initialValue) => {
      return getSessionStorage(key, initialValue)
    },
    setItem: (key, newValue) => {
      return setSessionStorage(key, Array.from(new Set(newValue)))
    },
  },
)
