import { twMerge } from 'tailwind-merge'
import Stomach from '@/assets/icons/v1/stomach.svg'
import type { IconProps } from '@/v1/Icons/types'

const StomachIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <Stomach
      width={size}
      height={size}
      className={twMerge('fill-stomach', className)}
      {...restProps}
    />
  )
}

export default StomachIcon
