import { twMerge } from 'tailwind-merge'
import Setting from '@/assets/icons/v1/setting.svg'
import type { IconProps } from '@/v1/Icons/types'

const SettingIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <Setting
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default SettingIcon
