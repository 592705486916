import { twMerge } from 'tailwind-merge'
import Tumor from '@/assets/icons/v1/tumor.svg'
import type { IconProps } from '@/v1/Icons/types'

const TumorIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Tumor
      width={size}
      height={size}
      className={twMerge('fill-grey-700', className)}
      {...restProps}
    />
  )
}

export default TumorIcon
