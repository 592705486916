import Google from '@/assets/icons/v1/google.svg'
import type { IconProps } from '@/v1/Icons/types'

const GoogleIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Google width={size} height={size} className={className} {...restProps} />
  )
}

export default GoogleIcon
