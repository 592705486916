import {
  ANDROID_APP_VERSION,
  AUTH_PROVIDER_KEY,
  TOKENS_KEY,
  INITIAL_TOKENS,
  MOBILE_EVENTS,
} from '@/consts'
import { MAIN_KEY } from '@/stores'
import {
  checkOldAndroidVersion,
  isWebview,
  getLocalStorage,
  setLocalStorage,
  postMessageToApp,
  setSessionStorage,
} from '@/utils'
import { getUserRoleFromToken } from '@/utils/token'

export type UserRole = '' | 'manager' | null

export interface AuthInfo {
  isLoggedIn: boolean
  role: UserRole
}

export interface Tokens {
  accessToken: string
  refreshToken: string
}

/**
 * 로그인 여부와 사용자 권한을 반환합니다.
 * @example
 * const { isLoggedIn, role } = getAuth()
 * @returns  isLoggedIn: 로그인 여부, role: 사용자 권한
 */
export const getAuth = (): AuthInfo => {
  const { accessToken, refreshToken } = getLocalStorage(
    TOKENS_KEY,
    INITIAL_TOKENS,
  )

  const isLoggedIn = !!accessToken && !!refreshToken

  const role = isLoggedIn ? getUserRoleFromToken(accessToken) : null

  return { isLoggedIn, role }
}

/**
 * 로컬스토리지에서 토큰을 가져옵니다.
 * @returns 토큰 객체 { accessToken, refreshToken }
 */
export function getAuthToken() {
  return getLocalStorage(TOKENS_KEY, INITIAL_TOKENS)
}

/**
 * 로컬스토리지에 토큰을 저장합니다.
 * @param tokens 토큰 객체 { accessToken, refreshToken }
 */
export const setAuthToken = (tokens: Tokens) => {
  setLocalStorage(TOKENS_KEY, tokens)
}

const clearSessionStorage = () => {
  sessionStorage.clear()
  // 메인 페이지 최초 방문 여부는 유지한다. 로그아웃/탈퇴 시 메인 바텀시트를 또 보여주지 않기 위함.
  setSessionStorage(MAIN_KEY, {
    hasMainViewed: true,
    cancerType: undefined,
  })
}

const clearLocalStorage = () => {
  const authProvider = getLocalStorage(AUTH_PROVIDER_KEY, '')
  localStorage.clear()
  // 로그인 방식은 유지. 비로그인 유저에게 이전 로그인 수단을 보여주기 위함.
  setLocalStorage(AUTH_PROVIDER_KEY, authProvider)
}

/**
 * 세션스토리지와 로컬스토리지에서 내 정보를 제거합니다.
 */
export const logout = () => {
  clearSessionStorage()
  clearLocalStorage()

  if (isWebview()) postMessageToApp(MOBILE_EVENTS.LOGOUT)
}

/**
 * 앱에서 로그아웃 이벤트를 전달받았을 때, 웹에서 로그아웃 처리를 합니다.
 * 모바일 앱에서만 호출하는 함수이다.
 */
export const weblogout = ({ type }: { type: 'logout' | 'unregister' }) => {
  // Android 버전 1.0.12 및 이전 버전 경우 구 버전으로 간주
  const isOldAndroidVersion = checkOldAndroidVersion(ANDROID_APP_VERSION)

  clearSessionStorage()
  clearLocalStorage()

  // 1. 로그아웃인 경우 새로고침을 한다.
  // 2. 구버전 안드로이드인 경우 새로고침을 한다.
  if (type === 'logout' || isOldAndroidVersion) {
    location.reload()
  } else {
    // 신버전 안드로이드 및 iOS인 경우 쿼리스트링을 붙여서 메인 페이지로 이동한다.
    // 메인 화면에서는 쿼리스트링이 있는 경우 탈퇴 토스트를 띄운다.
    location.href = `${location.origin}/?unregister=true`
  }

  if (isWebview()) {
    window.scrollTo(0, 0)
  }
}
