import { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Alert from '@/v1/Modal/Alert'
import type { ModalButtonType } from '@/v1/Modal/types'

const DEFAULT_CONFIRM_BUTTON = {
  text: '확인',
}

interface AlertModalProps {
  title?: string
  message: ReactNode
  confirmButton?: ModalButtonType
  closeable?: boolean
  onClose?: () => void
}

const AlertModal = NiceModal.create(
  ({
    title,
    message,
    confirmButton = DEFAULT_CONFIRM_BUTTON,
    closeable = false,
    onClose,
  }: AlertModalProps) => {
    const modal = useModal()
    const handleConfirm = () => {
      confirmButton?.onClick?.()
      modal.hide()
    }

    const handleClose = () => {
      onClose?.()
      modal.hide()
    }

    return (
      <Alert
        opened={modal.visible}
        title={title}
        message={message}
        closeable={closeable}
        confirmButtonText={confirmButton.text}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    )
  },
)

export default AlertModal
