import { HTTPError } from 'ky-universal'
import { ERROR_MESSAGES } from '@/consts'

/**
 *  HTTP 요청에 실패했을 때 던져지는 에러인지 여부를 반환한다.
 * 네트워크 실패, CORS 오류, 타임아웃, 잘못된 요청 형식, 브라우저 자체 에러 등
 * @param error
 */
export const isHTTPError = (error: unknown): error is HTTPError =>
  (error as HTTPError)?.response?.status !== undefined

export const isError = (error: unknown): error is Error =>
  error instanceof Error

/**
 * catch 블록에서 에러를 받아서 처리하기 전에 normalizeError를 통해 에러 객체를 통일한다.
 */
export const normalizeError = (error: unknown) => {
  if (isHTTPError(error)) return error
  if (isError(error)) return new Error(error.message)
  return new Error(ERROR_MESSAGES.ERROR)
}
