import LinkedInCircle from '@/assets/icons/v1/linkedInCircle.svg'
import type { IconProps } from '@/v1/Icons/types'

const LinkedInCircleIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <LinkedInCircle height={size} className={className} {...restProps} />
}

export default LinkedInCircleIcon
