'use client'

import { ReactNode } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import { CloseIconButton } from '@/v1/Modal/CloseIconButton'
import { ConfirmButton } from '@/v1/Modal/ConfirmButton'
import Modal from '@/v1/Modal/index'
import Overlay from '@/v1/Modal/Overlay'
import { dialogStyle, modalLayoutStyle } from '@/v1/Modal/styles'

interface AlertProps {
  opened: boolean
  title?: string
  message: ReactNode
  confirmButtonText: string
  closeable: boolean
  onConfirm: () => void
  onClose: () => void
}

const Alert = ({
  opened,
  title,
  message,
  confirmButtonText,
  closeable,
  onConfirm,
  onClose,
}: AlertProps) => {
  useToggleBodyScroll(opened)

  if (!opened) return null

  return (
    <Modal>
      <Overlay closeable={closeable} onClose={onClose} />
      <div
        className={clsx(twMerge(modalLayoutStyle), dialogStyle)}
        role="alertdialog"
      >
        {closeable && <CloseIconButton onClose={onClose} />}
        {title && <h5 className="pb-2 prose-h5">{title}</h5>}
        <div className={clsx('pb-6', 'whitespace-pre-line', 'text-grey-800')}>
          {message}
        </div>
        <ConfirmButton text={confirmButtonText} onClick={onConfirm} />
      </div>
    </Modal>
  )
}

export default Alert
