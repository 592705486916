import { StateSnapshot } from 'react-virtuoso'
import { atom } from 'jotai'

export const INITIAL_VIRTUOSO_SNAPSHOT = undefined

export const virtuosoAtom = atom<
  | {
      name: string
      snapshot: StateSnapshot
    }
  | undefined
>(INITIAL_VIRTUOSO_SNAPSHOT)
