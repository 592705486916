import clsx from 'clsx'
import Filter from '@/assets/icons/filter.svg'
import { SvgProps } from '@/types'

const FilterIcon = ({
  alt = 'filter',
  className = '',
  width = 20,
  height = 20,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <Filter
        width={width}
        height={height}
        className={clsx('fill-gray-100', className)}
        {...rest}
      />
    </i>
  )
}

export default FilterIcon
