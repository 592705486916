/**
 * @param {string} path
 * @returns {URL} { pathname, query, hash }
 * auth 페이지에서 로그인 성공시(handleLoginSuccess),
 * redirect path로 replace해주기 위한 URL을 return하는 함수
 */

export const parseURL = (path: string) => {
  const url = new URL(path, window.location.origin)

  return {
    pathname: url.pathname,
    query: Object.fromEntries(url.searchParams),
    hash: url.hash,
  }
}
