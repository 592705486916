import { twMerge } from 'tailwind-merge'
import Lock from '@/assets/icons/v1/lock.svg'
import type { IconProps } from '@/v1/Icons/types'

const LockIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Lock
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default LockIcon
