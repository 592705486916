import { twMerge } from 'tailwind-merge'
import { icons } from './icons'
import type { SVGProps } from './icons'

const MaterialSymbol = ({ name, size = 24, className, ...rest }: SVGProps) => {
  const Icon = icons[name]

  return (
    <Icon
      name={name}
      width={size}
      height={size}
      className={twMerge('fill-grey-900', className)}
      {...rest}
    />
  )
}

export default MaterialSymbol
