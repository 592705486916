import { handleError } from './handleError'

/**
 * 에러 코드와 에러 메시지를 반환하여, 함수 호출한 곳에서 손수 에러 처리를 한다.
 * @param error
 * @param ignoreCodes 직접 처리할 에러 코드 배열
 * @returns 에러 상태코드, 에러 메시지
 */
export const handleCustomError = async ({
  error,
  ignoreCodes,
}: {
  error: unknown
  ignoreCodes: number[]
}) => {
  // 기본 에러 처리
  const { status = -1, errorResponse = '' } =
    (await handleError(error, ignoreCodes)) ?? {}
  // 직접 에러를 처리하기 위해 에러 메시지를 반환한다. (400 에러의 경우 detail을 반환한다.)
  return { code: status, errorResponse }
}
