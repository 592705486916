import clsx from 'clsx'

interface Props {
  onClose?: () => void
  closeable?: boolean
}

const Overlay = ({ onClose, closeable }: Props) => {
  const handleClickOverlay = () => {
    if (closeable) {
      onClose?.()
    }
  }

  return (
    <div
      onClick={handleClickOverlay}
      className={clsx(
        'fixed',
        'w-full',
        'h-full',
        'top-0',
        'left-0',
        'bg-black',
        'opacity-80',
      )}
    />
  )
}

export default Overlay
