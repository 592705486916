import InstagramCircle from '@/assets/icons/v1/instagramCircle.svg'
import type { IconProps } from '@/v1/Icons/types'

const InstagramCircleIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <InstagramCircle height={size} className={className} {...restProps} />
}

export default InstagramCircleIcon
