import { twMerge } from 'tailwind-merge'
import Calendar from '@/assets/icons/v1/calendar.svg'
import type { IconProps } from '@/v1/Icons/types'

const CalendarIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <Calendar
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default CalendarIcon
