import Image from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'
import { S3_IMAGE_URL } from '@/config'

/** 홈 페이지로 이동하는 로고 버튼 */
const LogoHomeButton = ({
  handleClick,
  gaSelector = '',
}: {
  handleClick?: () => void
  gaSelector?: string
}) => {
  return (
    <Link
      href="/"
      passHref
      className={clsx('flex', 'items-center', 'h-full', 'px-6')}
      onClick={handleClick}
      {...(gaSelector ? { 'data-ga': gaSelector } : {})}
    >
      <Image
        src={`${S3_IMAGE_URL}/lunit_care_logo.svg`}
        width={155}
        height={24}
        alt="루닛케어 로고"
        className="pointer-events-none h-6"
        priority
      />
    </Link>
  )
}

export default LogoHomeButton
