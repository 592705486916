import NiceModal from '@ebay/nice-modal-react'
import { REST_API_ENDPOINT } from '@/config'
import { ERROR_MESSAGES } from '@/consts'
import AlertModal from '@/hooks/useModal/AlertModal'
import { getAuthToken } from '@/utils/auth'
import { requestTokenRefresh } from './handleRequest'
import { schemaErrorResponse } from './types'

interface FileUpload {
  endpoint: string
  file: File
  handleProgress: (e: ProgressEvent) => void
  // 파일 업로드 요청이 완료된 후 수행할 함수
  handleLoadComplete: () => void
}

// 유효한 확장자 파일인지 확인
const isValidFileType = (accept: string, fileName: string) => {
  return accept
    .split(', ')
    .some((type) => fileName?.toLowerCase().endsWith(type.toLocaleLowerCase()))
}

const showErrorAlert = ({
  title,
  message = ERROR_MESSAGES.UPLOAD_FAILED,
}: {
  title?: string
  message?: string
}) => {
  NiceModal.show(AlertModal, {
    ...(title ? { title } : {}),
    message,
  })
}

/**
 * 파일 업로드 요청을 처리하는 함수
 * @param endpoint 업로드 요청을 보낼 endpoint path. 앞에 '/' 없다. ex) clinical_notes/
 * @param file 업로드할 파일
 * @param handleProgress 파일 업로드 진행 상황을 처리하는 함수
 * @param handleLoadComplete 파일 업로드 요청이 완료된 후 수행할 함수
 */
export const handleFileUpload = ({
  endpoint,
  file,
  handleProgress,
  handleLoadComplete,
}: FileUpload) => {
  const { accessToken } = getAuthToken()

  const formData = new FormData()
  formData.append('file', file)

  const xhr = new XMLHttpRequest()

  // 서버에서 반환한 에러 메시지를 처리
  const handleLoadError = () => {
    try {
      const errorResponse = schemaErrorResponse.parse(
        JSON.parse(xhr.responseText),
      )
      showErrorAlert({ message: errorResponse.detail })
    } catch (error) {
      showErrorAlert({})
    } finally {
      handleLoadComplete()
    }
  }

  xhr.upload.addEventListener('progress', handleProgress)

  // // 서버에서 응답을 정상적으로 반환함 (성공 및 에러를 반환한 경우 모두 처리)
  xhr.addEventListener('load', async () => {
    if (xhr.status === 401) {
      try {
        // 토큰 갱신 로직 추가
        const { refreshToken } = getAuthToken()
        const newToken = await requestTokenRefresh(refreshToken)
        xhr.open('post', `${REST_API_ENDPOINT}/${endpoint}`)
        xhr.setRequestHeader('Authorization', `Bearer ${newToken?.access}`)
        xhr.send(formData)
      } catch (error) {
        showErrorAlert({})
      }
      return
    }
    if (xhr.status >= 200 && xhr.status < 300) handleLoadComplete()
    else handleLoadError()
  })

  xhr.addEventListener('error', () => showErrorAlert({}))
  xhr.addEventListener('timeout', () => showErrorAlert({}))
  xhr.addEventListener('abort', () => showErrorAlert({}))

  xhr.open('post', `${REST_API_ENDPOINT}/${endpoint}`)

  xhr.setRequestHeader(
    'Authorization',
    accessToken ? `Bearer ${accessToken}` : '',
  )

  xhr.send(formData)
}

/**
 * 파일 업로드 전 유효성 검사
 * @param file 업로드할 파일
 * @param maxFileSize 파일 최대 용량
 * @param accept 허용할 파일 확장자
 * @param handleReset 파일 입력 필드를 초기화하는 함수
 * @returns 유효성 검사가 통과하면 파일 객체를 반환하고, 실패하면 false를 반환
 */
export const validateFile = ({
  file,
  maxFileSize,
  maxFileSizeError,
  accept,
  handleReset,
}: {
  file: File | null
  maxFileSize: number
  maxFileSizeError: {
    title: string
    message: string
  }
  accept: string
  handleReset?: () => void
}) => {
  if (!file) return false

  if (file?.size >= maxFileSize) {
    showErrorAlert({
      title: maxFileSizeError.title,
      message: maxFileSizeError.message,
    })
    handleReset?.()
    return false
  }
  if (!isValidFileType(accept, file?.name)) {
    showErrorAlert({
      title: '파일 업로드 불가',
      message: ERROR_MESSAGES.getWrongFileFormatError(accept),
    })
    handleReset?.()
    return false
  }

  return file
}
