import { twMerge } from 'tailwind-merge'
import FlagFilled from '@/assets/icons/v1/flagFilled.svg'
import type { IconProps } from '@/v1/Icons/types'

const FlagFilledIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <FlagFilled
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default FlagFilledIcon
