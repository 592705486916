import { useEffect, useState } from 'react'

/**
 * next.js에서 클라이언트 환경인지 확인하는 hook
 * hydration 이슈를 해결하기 위해 사용
 * @returns isClient - 클라이언트 환경 여부
 */
const useIsClient = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}

export default useIsClient
