import clsx from 'clsx'
import type { Placement } from 'tippy.js'

export const TOOLTIP_STYLE = [
  'prose-p3',
  'relative',
  'max-w-[260px]',
  'px-3',
  'py-2',
  'bg-grey-800',
  'border-gray-30',
  'text-white',
  'rounded-md',
  'shadow-[0_2px_16px_0_rgba(46, 49, 52, 0.1)]',
].join(' ')

/**
 * Tooltip 컴퍼넌트
 * @param placement 툴팁이 표시될 위치
 * @param content 툴팁에 표시될 내용
 */
const Tooltip = ({
  placement,
  content,
}: {
  content: string
  placement?: Placement
}) => {
  return (
    <div
      className={clsx(TOOLTIP_STYLE, {
        'mx-5': placement === 'top' || placement === 'bottom',
      })}
      data-popper-placement={placement}
      tabIndex={-1}
    >
      {content}
      <span
        data-popper-arrow=""
        className={clsx(
          {
            'w-2 h-1': placement === 'top' || placement === 'bottom',
            'w-1 h-2': placement === 'left' || placement === 'right',
          },
          {
            'bottom-[-4px]': placement === 'top',
            'top-[-6px]': placement === 'bottom',
            'right-0': placement === 'left',
            'left-0': placement === 'right',
            'left-1/2': placement === 'bottom' || placement === 'top',
            '-ml-1': placement === 'bottom' || placement === 'top',
          },
        )}
      >
        <span
          className={clsx(
            'absolute',
            'w-0',
            'h-0',
            'border-x-4',
            'border-t-0',
            'border-b-[6px]',
            'border-x-transparent',
            'border-t-transparent',
            'border-b-grey-800',
            'text-[0px]',
            {
              'top-1/2 -mt-[3px]':
                placement === 'left' || placement === 'right',
              'right-[-7px]': placement === 'left',
              'left-[-7px]': placement === 'right',
            },
            /* arrow 방향 */
            {
              'transform rotate-180': placement === 'top',
              'transform rotate-0': placement === 'bottom',
              'transform rotate-90': placement === 'left',
              'transform -rotate-90': placement === 'right',
            },
          )}
        />
      </span>
    </div>
  )
}

export default Tooltip
