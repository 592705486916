/**
 * 쿼리스트링에서 키에 해당하는 값을 주소창에서 제거한다.
 * @param key 쿼리스트링 키
 * @returns 제거된 URL 객체
 */
export const removeQueryString = (key: string) => {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  return url
}
