import React, { PropsWithChildren } from 'react'
import Portal from '@/components/Portal'

const Modal = ({ children }: PropsWithChildren) => {
  return (
    <Portal selector="#portal">
      <div className="isolate">{children}</div>
    </Portal>
  )
}

export default Modal
