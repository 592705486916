import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { buttonLayoutStyle, buttonLayoutGapStyles } from '@/v1/Button/styles'
import type { BaseButtonProps } from '@/v1/Button/types'

// 기본 버튼 스타일
const DEFAULT_BUTTON_STYLE = [
  'min-w-[80px]',
  'min-h-[64px]',
  'py-5',
  'px-4',
  'rounded-[16px]',
  'font-bold',
  '[&_svg]:w-[24px]',
  '[&_svg]:h-[24px]',
].join(' ')
// 기본 버튼 색상
const DEFAULT_BUTTON_COLOR = [
  'text-grey-900',
  'bg-white',
  'border-[1px]',
  'border-grey-200',
  'hover:text-lavendar-700',
  'hover:bg-lavendar-100',
  'hover:border-lavendar-100',
  '[&_svg]:fill-grey-900',
  '[&_svg]:hover:fill-lavendar-700',
].join(' ')
const buttonColor = {
  disabled: [
    'text-grey-400',
    'bg-grey-100',
    'border-[1px]',
    'border-grey-100',
    '[&_svg]:fill-grey-400',
    'disabled:cursor-not-allowed',
  ].join(' '),
  active: [
    'text-lavendar-700',
    'bg-lavendar-100',
    'border-lavendar-100',
    '[&_svg]:fill-lavendar-700',
  ].join(' '),
  default: DEFAULT_BUTTON_COLOR,
}
// XLargeButton 스타일을 외부(RadioButton)에서 공유할 수 있도록 export
export const BUTTON_STYLE = `${DEFAULT_BUTTON_STYLE} ${DEFAULT_BUTTON_COLOR} ${buttonLayoutStyle}`

export const XLargeButton = ({
  leftIcon,
  rightIcon,
  block,
  disabled: isDisabled,
  active: isActive,
  text,
  subText,
  ...restProps
}: BaseButtonProps & {
  text: string
  subText?: string
  active?: boolean
  block?: boolean
}) => {
  const buttonLayoutGapStyle =
    buttonLayoutGapStyles[
      [leftIcon, rightIcon].every((icon) => Boolean(icon)) ? 1 : 0
    ]
  const buttonStatus = isDisabled ? 'disabled' : isActive ? 'active' : 'default'

  return (
    <button
      type="button"
      className={clsx(
        DEFAULT_BUTTON_STYLE,
        buttonColor[buttonStatus],
        'group',
        { 'w-full': !block },
      )}
      disabled={isDisabled}
      {...restProps}
    >
      <div
        className={clsx(
          'flex',
          'justify-center',
          'items-center',
          'pointer-events-none',
        )}
      >
        <p className={clsx('flex', buttonLayoutStyle, buttonLayoutGapStyle)}>
          {leftIcon && <span className="ml-[-3px]">{leftIcon}</span>}
          <span>{text}</span>
          {rightIcon && <span className="mr-[-3px]">{rightIcon}</span>}
        </p>
        {subText && <SubText>{subText}</SubText>}
      </div>
    </button>
  )
}

const SubText = ({ children }: PropsWithChildren) => {
  return (
    <span
      className={clsx(
        'block',
        'mt-1',
        'text-grey-600',
        'font-medium',
        'prose-p3',
        'hover:text-lavendar-700',
        'group-disabled:text-grey-400',
        'group-hover:text-lavendar-700',
        'group-[.text-lavendar-700]:text-lavendar-700',
      )}
    >
      {children}
    </span>
  )
}

XLargeButton.SubText = SubText
SubText.displayName = 'XLargeButton.SubText'
