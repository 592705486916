import MessengerCircle from '@/assets/icons/v1/messengerCircle.svg'
import type { IconProps } from '@/v1/Icons/types'

const MessengerCircleIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <MessengerCircle height={size} className={className} {...restProps} />
}

export default MessengerCircleIcon
