import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLE } from '@/v1/Tooltip/Tooltip'

const Tooltip = () => {
  return (
    <span
      className={twMerge(
        TOOLTIP_STYLE,
        'absolute',
        '-top-[48px]',
        'before:content-[" "]',
        'before:absolute',
        'before:w-0',
        'before:h-0',
        'before:-bottom-1.5',
        'before:left-1/2',
        'before:-ml-[3px]',
        'before:border-x-4',
        'before:border-t-0',
        'before:border-b-[6px]',
        'before:border-x-transparent',
        'before:border-t-transparent',
        'before:border-b-grey-800',
        'before:transform',
        'before:rotate-180',
      )}
    >
      최근에 로그인 했어요.
    </span>
  )
}

export default Tooltip
