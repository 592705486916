import clsx from 'clsx'
import { NaverSymbolIcon } from '@/components/NewIcons'
import { AUTH_PROVIDER_KEY } from '@/consts'
import { getLocalStorage } from '@/utils'
import Tooltip from '../Tooltip'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  text: string
  size?: 'lg' | 'md'
  className?: string
}

export const NaverLoginButton = ({
  text,
  size = 'md',
  className,
  ...rest
}: Props) => {
  const authProvider = getLocalStorage<string>(AUTH_PROVIDER_KEY, '')

  return (
    <button
      type="button"
      className={clsx(
        'relative',
        'flex',
        'justify-center',
        'items-center',
        'w-full',
        'text-white',
        'rounded-lg',
        'bg-green-naver',
        'cursor-pointer',
        { 'py-2.5': size === 'md' },
        { 'py-[14.5px]': size === 'lg' },
        className,
      )}
      {...rest}
    >
      <NaverSymbolIcon className="mr-1" />
      <span className={size === 'lg' ? 'prose-p1' : 'prose-p2'}>
        <b>{text}</b>
      </span>
      {authProvider === 'NAVER' && <Tooltip />}
    </button>
  )
}
