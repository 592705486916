import { schema400Response, schemaErrorResponse } from './types'

/**
 * 에러 응답을 파싱하여 에러 메시지를 반환한다.
 * 400인 경우는 { 필드명: [에러메시지] }이므로 Record<string, string[]>를 반환한다.
 * 그 외는 { detail: 에러메시지 }로 나오므로 detail 값을 에러 메시지로 사용한다.
 * @param status  HTTP 상태 코드
 * @param response  HTTP 응답 객체
 * @returns 에러 메시지 문자열 또는 Record<string, string[]>
 */
const getErrorResponse = async (status: number, response: Response) => {
  try {
    const errorResponse = await response.json()
    if (status === 400) {
      return schema400Response.parse(errorResponse)
    }
    return schemaErrorResponse.parse(errorResponse)
  } catch (error) {}
}

export default getErrorResponse
