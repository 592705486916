//"#" 프래그먼트가 붙어있는 해시를 얻기 위한 정규표현식이다.
// "#"로 시작하며, "?" 또는 "#"가 나오기 직전까지 문자를 매칭한다.
const HASH_REG_EXP = /#([^#?]*)/g
// "?" 가 붙어있는 쿼리 파라미터를 얻기 위한 정규표현식이다.
// "?"로 시작하며, "#" 또는 "?"가 나오기 직전까지 문자를 매칭한다.
const QUERY_REG_EXP = /\?([^#?]*)/g

/** 임의의 주소 문자열을 받아서 아래 조건을 만족하는 유효 url을 반환한다.
 * - 쿼리 파라미터를 항상 해시 프래그먼트보다 먼저 사용하도록 한다.
 * - 여러 쿼리 파라미터 사용 시 "?"가 여러개 존재하면, "&"로 구분하도록 한다.
 * - 중복 쿼리 파라미터 혹은 해시 프래그먼트는 제거한다.
 * @param url 특정 주소 문자열
 * @returns 위의 조건대로 처리한 유효 url
 * @example
 * // returns '/breast/guides/?id=123#소분류'
 * normalizeURL('/breast/guides/#소분류?id=123#소분류')
 */
export const normalizeURL = (url: string) => {
  const hashFragments = [...url.matchAll(HASH_REG_EXP)].map((match) => match[1])
  const queryParmeters = [...url.matchAll(QUERY_REG_EXP)].map(
    (match) => match[1],
  )
  const hashFragmentSet = [...new Set(hashFragments)].filter(
    (hash) => hash !== '',
  ) // 중복 제거
  const queryParmeterSet = [...new Set(queryParmeters)].filter(
    (query) => query !== '',
  )

  const urlWithoutHash = hashFragments.reduce((urlWithoutHash, hash) => {
    return urlWithoutHash.replace(`#${hash}`, '')
  }, url)

  const urlWithoutHashAndQuery = queryParmeters.reduce(
    (urlWithoutHashAndQuery, param) => {
      return urlWithoutHashAndQuery.replace(`?${param}`, '')
    },
    urlWithoutHash,
  )

  const hash = hashFragmentSet.length ? `#${hashFragmentSet.join('#')}` : ''
  const queries = queryParmeterSet.length
    ? `?${queryParmeterSet.join('&')}`
    : ''

  return `${urlWithoutHashAndQuery}${queries}${hash}`
}
