import { twMerge } from 'tailwind-merge'
import Time from '@/assets/icons/v1/time.svg'
import type { IconProps } from '@/v1/Icons/types'

const TimeIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Time
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default TimeIcon
