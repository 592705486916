import { twMerge } from 'tailwind-merge'
import AddCreditCardFilled from '@/assets/icons/v1/addCreditCardFilled.svg'
import type { IconProps } from '@/v1/Icons/types'

const AddCreditCardFilledIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <AddCreditCardFilled
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default AddCreditCardFilledIcon
