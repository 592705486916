import Instagram from '@/assets/icons/v1/instagram.svg'
import type { IconProps } from '@/v1/Icons/types'

const InstagramIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <Instagram height={size} className={className} {...restProps} />
}

export default InstagramIcon
