import { isHTTPError } from '@/utils/httpClient/normalizeError'
import getErrorResponse from './getErrorResponse'
import { maintenanceErrorResponse } from './types'

export const handleServerSidePropsError = async (error: unknown) => {
  if (!isHTTPError(error)) {
    return {
      redirect: {
        destination: '/404',
        permanent: false,
      },
    }
  }
  const response =
    (await getErrorResponse(error.response.status, error.response)) ?? ''

  if (error.response.status === 404) {
    return {
      redirect: {
        destination: '/404',
        permanent: false,
      },
    }
  }

  if (error.response.status === 503) {
    // 긴급에러이면 503 페이지로 이동
    const errorResponse = maintenanceErrorResponse.safeParse(response)
    if (errorResponse.success)
      return {
        redirect: {
          destination: `/503/?maintenanceStart=${errorResponse.data.maintenanceStart}&maintenanceEnd=${errorResponse.data.maintenanceEnd}`,
          permanent: false,
        },
      }
    // 일반 503 에러이면 500 페이지로 이동
    return {
      redirect: {
        destination: '/500',
        permanent: false,
      },
    }
  }

  return {
    redirect: {
      destination: '/500',
      permanent: false,
    },
  }
}
