import AccountBalanceFill from './account_balance_fill.svg'
import Add from './add.svg'
import AddCircle from './add_circle.svg'
import AddPhotoAlternate from './add_photo_alternate.svg'
import ArrowBackIosNew from './arrow_back_ios_new.svg'
import ArrowForward from './arrow_forward.svg'
import Assignment from './assignment.svg'
import Bookmark from './bookmark.svg'
import BookmarkAdd from './bookmark_add.svg'
import BookmarkFill from './bookmark_fill.svg'
import CalculateFill from './calculate_fill.svg'
import CalendarAddOn from './calendar_add_on.svg'
import Call from './call.svg'
import CallFill from './call_fill.svg'
import CaptivePortal from './captive_portal.svg'
import Check from './check.svg'
import CheckCircleFill from './check_circle_fill.svg'
import ChevronLeft from './chevron_left.svg'
import ChevronRight from './chevron_right.svg'
import Close from './close.svg'
import ContactSupport from './contact_support.svg'
import ContactSupportFill from './contact_support_fill.svg'
import DeleteForever from './delete_forever.svg'
import DeleteForeverFill from './delete_forever_fill.svg'
import Diagnosis from './diagnosis.svg'
import DiscoverTune from './discover_tune.svg'
import Download from './download.svg'
import Edit from './edit.svg'
import EditFill from './edit_fill.svg'
import EditSquareFill from './edit_square_fill.svg'
import EmojiObjectsFill from './emoji_objects_fill.svg'
import ErrorFill from './error_fill.svg'
import EscalatorWarningFill from './escalator_warning_fill.svg'
import EventAvailable from './event_available.svg'
import ExpandLess from './expand_less.svg'
import ExpandMore from './expand_more.svg'
import FactCheckFill from './fact_check_fill.svg'
import FormatListBulleted from './format_list_bulleted.svg'
import Forum from './forum.svg'
import ForumFill from './forum_fill.svg'
import Forward10 from './forward_10.svg'
import HeadSetMic from './headset_mic.svg'
import HealthAndSafety from './health_and_safety.svg'
import HeartPlus from './heart_plus_fill.svg'
import Help from './help.svg'
import HelpFill from './help_fill.svg'
import Home from './home.svg'
import HomeFill from './home_fill.svg'
import HomeHealth from './home_health_fill.svg'
import ImagesmodeFill from './imagesmode_fill.svg'
import Info from './info.svg'
import InfoFill from './info_fill.svg'
import Menu from './menu.svg'
import Mic from './mic.svg'
import MicFill from './mic_fill.svg'
import MoreVert from './more_vert.svg'
import NavigateNext from './navigate_next.svg'
import Notifications from './notifications.svg'
import Pause from './pause.svg'
import Person from './person.svg'
import PersonFill from './person_fill.svg'
import PhoneInTalk from './phone_in_talk.svg'
import PillFill from './pill_fill.svg'
import PlayArrow from './play_arrow.svg'
import PolicyFill from './policy_fill.svg'
import QuestionMark from './question_mark.svg'
import Refresh from './refresh.svg'
import Replay10 from './replay_10.svg'
import Robot from './robot.svg'
import SchoolFill from './school_fill.svg'
import Search from './search.svg'
import SendFill from './send_fill.svg'
import Settings from './settings.svg'
import Share from './share.svg'
import StarFill from './star_fill.svg'
import Stethoscope from './stethoscope.svg'
import ThumbDown from './thumb_down.svg'
import ThumbUp from './thumb_up.svg'
import Upload from './upload.svg'

export type IconName = keyof typeof icons

export interface SVGProps extends React.SVGAttributes<HTMLOrSVGElement> {
  name: IconName
  size?: number
}

export const icons = {
  account_balance_fill: (args: SVGProps) => <AccountBalanceFill {...args} />,
  add: (args: SVGProps) => <Add {...args} />,
  add_circle: (args: SVGProps) => <AddCircle {...args} />,
  add_photo_alternate: (args: SVGProps) => <AddPhotoAlternate {...args} />,
  arrow_back_ios_new: (args: SVGProps) => <ArrowBackIosNew {...args} />,
  arrow_forward: (args: SVGProps) => <ArrowForward {...args} />,
  assignment: (args: SVGProps) => <Assignment {...args} />,
  bookmark: (args: SVGProps) => <Bookmark {...args} />,
  bookmark_add: (args: SVGProps) => <BookmarkAdd {...args} />,
  bookmark_fill: (args: SVGProps) => <BookmarkFill {...args} />,
  calculate_fill: (args: SVGProps) => <CalculateFill {...args} />,
  calendar_add_on: (args: SVGProps) => <CalendarAddOn {...args} />,
  call: (args: SVGProps) => <Call {...args} />,
  call_fill: (args: SVGProps) => <CallFill {...args} />,
  captive_portal: (args: SVGProps) => <CaptivePortal {...args} />,
  check: (args: SVGProps) => <Check {...args} />,
  check_circle_fill: (args: SVGProps) => <CheckCircleFill {...args} />,
  chevron_left: (args: SVGProps) => <ChevronLeft {...args} />,
  chevron_right: (args: SVGProps) => <ChevronRight {...args} />,
  close: (args: SVGProps) => <Close {...args} />,
  contact_support: (args: SVGProps) => <ContactSupport {...args} />,
  contact_support_fill: (args: SVGProps) => <ContactSupportFill {...args} />,
  delete_forever: (args: SVGProps) => <DeleteForever {...args} />,
  delete_forever_fill: (args: SVGProps) => <DeleteForeverFill {...args} />,
  diagnosis: (args: SVGProps) => <Diagnosis {...args} />,
  discover_tune: (args: SVGProps) => <DiscoverTune {...args} />,
  download: (args: SVGProps) => <Download {...args} />,
  edit: (args: SVGProps) => <Edit {...args} />,
  edit_fill: (args: SVGProps) => <EditFill {...args} />,
  edit_square_fill: (args: SVGProps) => <EditSquareFill {...args} />,
  emoji_objects_fill: (args: SVGProps) => <EmojiObjectsFill {...args} />,
  error_fill: (args: SVGProps) => <ErrorFill {...args} />,
  escalator_warning_fill: (args: SVGProps) => (
    <EscalatorWarningFill {...args} />
  ),
  event_available: (args: SVGProps) => <EventAvailable {...args} />,
  expand_less: (args: SVGProps) => <ExpandLess {...args} />,
  expand_more: (args: SVGProps) => <ExpandMore {...args} />,
  fact_check_fill: (args: SVGProps) => <FactCheckFill {...args} />,
  format_list_bulleted: (args: SVGProps) => <FormatListBulleted {...args} />,
  forum: (args: SVGProps) => <Forum {...args} />,
  forum_fill: (args: SVGProps) => <ForumFill {...args} />,
  forward_10: (args: SVGProps) => <Forward10 {...args} />,
  headset_mic: (args: SVGProps) => <HeadSetMic {...args} />,
  heart_plus_fill: (args: SVGProps) => <HeartPlus {...args} />,
  health_and_safety_fill: (args: SVGProps) => <HealthAndSafety {...args} />,
  help: (args: SVGProps) => <Help {...args} />,
  help_fill: (args: SVGProps) => <HelpFill {...args} />,
  home: (args: SVGProps) => <Home {...args} />,
  home_fill: (args: SVGProps) => <HomeFill {...args} />,
  home_health_fill: (args: SVGProps) => <HomeHealth {...args} />,
  imagesmode_fill: (args: SVGProps) => <ImagesmodeFill {...args} />,
  info: (args: SVGProps) => <Info {...args} />,
  info_fill: (args: SVGProps) => <InfoFill {...args} />,
  menu: (args: SVGProps) => <Menu {...args} />,
  mic: (args: SVGProps) => <Mic {...args} />,
  mic_fill: (args: SVGProps) => <MicFill {...args} />,
  more_vert: (args: SVGProps) => <MoreVert {...args} />,
  navigate_next: (args: SVGProps) => <NavigateNext {...args} />,
  notifications: (args: SVGProps) => <Notifications {...args} />,
  pause: (args: SVGProps) => <Pause {...args} />,
  person: (args: SVGProps) => <Person {...args} />,
  person_fill: (args: SVGProps) => <PersonFill {...args} />,
  play_arrow: (args: SVGProps) => <PlayArrow {...args} />,
  policy_fill: (args: SVGProps) => <PolicyFill {...args} />,
  phone_in_talk: (args: SVGProps) => <PhoneInTalk {...args} />,
  question_mark: (args: SVGProps) => <QuestionMark {...args} />,
  refresh: (args: SVGProps) => <Refresh {...args} />,
  replay_10: (args: SVGProps) => <Replay10 {...args} />,
  school_fill: (args: SVGProps) => <SchoolFill {...args} />,
  search: (args: SVGProps) => <Search {...args} />,
  settings: (args: SVGProps) => <Settings {...args} />,
  share: (args: SVGProps) => <Share {...args} />,
  stethoscope: (args: SVGProps) => <Stethoscope {...args} />,
  upload: (args: SVGProps) => <Upload {...args} />,
  robot: (args: SVGProps) => <Robot {...args} />,
  send_fill: (args: SVGProps) => <SendFill {...args} />,
  pill_fill: (args: SVGProps) => <PillFill {...args} />,
  thumb_up: (args: SVGProps) => <ThumbUp {...args} />,
  thumb_down: (args: SVGProps) => <ThumbDown {...args} />,
  star_fill: (args: SVGProps) => <StarFill {...args} />,
}

export const ICON_NAMES = Object.keys(icons) as IconName[]
