import { usePathname } from 'next/navigation'
import { ACTION_TYPES, MOBILE_EVENTS } from '@/consts'
import { isWebview, postMessageToApp } from '@/utils'
import { loginWithKakao } from '@/utils/kakao'
import { loginWithNaver } from '@/utils/naver'
import useKakaoSdk from '../useKakaoSdk'

interface Props {
  redirectPath?: string
}

/**
 * 카카오, 네이버 로그인을 위한 커스텀 훅
 * @example
 * const { handleKakaoLogin, handleNaverLogin } = useSocialLogin()
 * @returns handleKakaoLogin, handleNaverLogin 함수 객체 반환 (각각 카카오, 네이버 로그인 함수)
 */
const useSocialLogin = () => {
  const pathname = usePathname()
  useKakaoSdk() // 카카오 sdk 로드

  /**
   * 카카오 로그인 함수
   * @param redirectPath 로그인 이후 리다이렉트할 경로
   */
  const handleKakaoLogin = ({ redirectPath = pathname }: Props) => {
    if (isWebview()) {
      const shouldCreateDocument = redirectPath.includes(
        ACTION_TYPES.CREATE_BOARD,
      )
      postMessageToApp(MOBILE_EVENTS.KAKAO_LOGIN, {
        shouldCreateDocument,
        redirectPath,
      })
    } else {
      loginWithKakao({ redirectPath })
    }
  }

  /**
   * 네이버 로그인 함수
   * @param redirectPath 로그인 이후 리다이렉트할 경로
   */
  const handleNaverLogin = ({ redirectPath = pathname }: Props) => {
    if (isWebview()) {
      const shouldCreateDocument = redirectPath.includes(
        ACTION_TYPES.CREATE_BOARD,
      )
      postMessageToApp(MOBILE_EVENTS.NAVER_LOGIN, {
        shouldCreateDocument,
        redirectPath,
      })
    } else {
      loginWithNaver({ redirectPath })
    }
  }

  return { handleKakaoLogin, handleNaverLogin }
}

export default useSocialLogin
