import jwt_decode, { JwtPayload } from 'jwt-decode'
import type { UserRole } from '@/utils/auth'

/**
 * JWT 토큰을 디코딩하여 유저 role을 얻는다.
 * @param accessToken
 */
export function getUserRoleFromToken(accessToken: string | null) {
  if (!accessToken) return null

  try {
    const { role } =
      jwt_decode<
        JwtPayload & {
          role: UserRole
        }
      >(accessToken)
    return role
  } catch (err) {
    return null
  }
}
