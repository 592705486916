import { twMerge } from 'tailwind-merge'
import Listen from '@/assets/icons/v1/listen.svg'
import type { IconProps } from '@/v1/Icons/types'

const ListenIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Listen
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default ListenIcon
