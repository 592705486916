/**
 * Kakao 토큰 받기 helper.
 */
import { z } from 'zod'
import {
  AUTH_PROVIDER,
  DEFAULT_OG_IMAGE,
  META_DESCRIPTIONS,
  META_TITLES,
} from '@/consts'
import type { KakaoShare } from '@/hooks/useKakaoShare'
import { encodeStateQuery } from '@/utils/url'
import { handleError, handleRequest } from './httpClient'

const LOGIN_URI = 'https://kauth.kakao.com/oauth/authorize?response_type=code'
const LOGOUT_URI = 'https://kauth.kakao.com/oauth/logout'

const termsVersionsSchema = z.object({
  versions: z.array(z.string()),
})
type TermsVersionsResponse = z.infer<typeof termsVersionsSchema>

/**
 * kakao 이용약관 동의 팝업에 띄울 serviceTerms 파라미터 리스트를
 * 서버로부터 받아온다.
 */
const getTermsVersions = async () => {
  try {
    const response = await handleRequest<TermsVersionsResponse>(
      'service-terms/',
      {
        method: 'get',
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

export async function loginWithKakao({
  redirectPath,
  reauthenticate = false,
}: {
  redirectPath: string
  reauthenticate?: boolean
}) {
  const { Kakao } = window
  if (!Kakao) return

  // 로그인 성공 후 이동할 경로를 state로 받을 수 있으며, 기본값은 현재 경로이다.
  const path = redirectPath || location.pathname

  const state = encodeStateQuery(AUTH_PROVIDER.KAKAO, path)
  const redirectUri = `${window.location.origin}/auth`

  try {
    // 서버에서 유효한 약관 버전 리스트를 받아 카카오로 전달한다.
    const terms = await getTermsVersions()

    window.location.href = `${LOGIN_URI}&client_id=${
      process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY ?? ''
    }&redirect_uri=${redirectUri}&state=${state}${
      terms && `&serviceTerms=${terms}`
    }${reauthenticate && `&prompt=login`}`
  } catch (error) {
    await handleError(error)
  }
}

export function logoutWithKakao() {
  window.location.href = `${LOGOUT_URI}?client_id=${
    process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY ?? ''
  }&logout_redirect_uri=${window.location.origin}/get-started`
}

export function shareWithKakao(props: KakaoShare) {
  const { Kakao } = window
  if (!Kakao) return

  const { type, url } = props

  Kakao.Share.sendDefault({
    objectType: props.type,
    ...(type === 'feed' && {
      content: {
        title: props.title ?? META_TITLES.MAIN,
        description: props.description ?? META_DESCRIPTIONS.MAIN,
        imageUrl: props.imageUrl ?? DEFAULT_OG_IMAGE,
        imageWidth: props.imageWidth ?? 800,
        imageHeight: props.imageHeight ?? 600,
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: url,
          webUrl: url,
        },
      },
      buttons: [
        {
          title: props.buttonTitle ?? '자세히 보기',
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
      ],
    }),
    ...(type === 'text' && {
      text: props.text,
      link: {
        mobileWebUrl: url,
        webUrl: url,
      },
    }),
  })
}
