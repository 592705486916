import { twMerge } from 'tailwind-merge'
import WebLink from '@/assets/icons/v1/webLink.svg'
import type { IconProps } from '@/v1/Icons/types'

const WebLinkIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <WebLink
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default WebLinkIcon
