import { twMerge } from 'tailwind-merge'
import Facebook from '@/assets/icons/v1/facebook.svg'
import type { IconProps } from '@/v1/Icons/types'

const FacebookIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return (
    <Facebook
      width={size}
      height={size}
      className={twMerge('fill-facebook-blue', className)}
      {...restProps}
    />
  )
}

export default FacebookIcon
