import YoutubeCircle from '@/assets/icons/v1/youtubeCircle.svg'
import type { IconProps } from '@/v1/Icons/types'

const YoutubeCircleIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <YoutubeCircle height={size} className={className} {...restProps} />
}

export default YoutubeCircleIcon
