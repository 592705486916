import { twMerge } from 'tailwind-merge'
import Unlock from '@/assets/icons/v1/unlock.svg'
import type { IconProps } from '@/v1/Icons/types'

const UnlockIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Unlock
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default UnlockIcon
