import Button from '@/v1/Button'
import type { ButtonProps } from '../Button/types'

interface Props extends ButtonProps {
  text?: string
}

export const ConfirmButton = ({ text, ...restProps }: Props) => {
  return (
    <Button variant="filled" size="medium" color="primary" {...restProps}>
      {text}
    </Button>
  )
}
