import { atom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import {
  getLocalStorage,
  setSessionStorage,
  setLocalStorage,
  getSessionStorage,
} from '@/utils'

type HealthInfoUpdateStatus = 'default' | 'updated' | 'checked'

export interface ReportBanner {
  healthInfoUpdateStatus: HealthInfoUpdateStatus
  reportBannerShown: boolean
}

export const INITIAL_REPORT_BANNER: ReportBanner = {
  healthInfoUpdateStatus: 'default', // 건강정보 업데이트 여부. 로컬 스토리지에 저장.
  reportBannerShown: false, // 리포트 배너 노출 여부. 세션 스토리지에 저장.
}

const REPORT_BANNER_SESSION_KEY = '__report_banner_shown__'
const REPORT_BANNER_LOCAL_KEY = '__healthinfo_update_status__'

export const reportBannerSessionAtom = atomWithStorage(
  REPORT_BANNER_SESSION_KEY,
  INITIAL_REPORT_BANNER.reportBannerShown,
  {
    ...createJSONStorage<boolean | ReportBanner>(() => sessionStorage),
    getItem: (key) => {
      return getSessionStorage<boolean | ReportBanner>(
        key,
        INITIAL_REPORT_BANNER.reportBannerShown,
      )
    },
    setItem: (key, newValue) => {
      return setSessionStorage(key, (newValue as any).reportBannerShown)
    },
  },
)

export const reportBannerLocalAtom = atomWithStorage(
  REPORT_BANNER_LOCAL_KEY,
  INITIAL_REPORT_BANNER.healthInfoUpdateStatus,
  {
    ...createJSONStorage<HealthInfoUpdateStatus | ReportBanner>(
      () => localStorage,
    ),
    getItem: (key) => {
      return getLocalStorage<HealthInfoUpdateStatus | ReportBanner>(
        key,
        INITIAL_REPORT_BANNER.healthInfoUpdateStatus,
      )
    },
    setItem: (key, newValue) => {
      return setLocalStorage(key, (newValue as any).healthInfoUpdateStatus)
    },
  },
)

/**
 * 리포트 업데이트 띠 배너 노출을 위한 상태
 * 건강정보 업데이트 시, 다음 세션까지만 띠 배너를 노출하고, 그 이후는 노출하지 않는다.
 * 건강정보 업데이트 여부는 로컬 스토리지에 저장한다.
 * 리포트 배너 노출 여부는 세션 스토리지에 저장한다.
 * 로컬 스토리지에 저장한 상태와 세션 스토리지에 저장한 상태를 하나의 atom으로 관리한다.
 */
export const reportBannerAtom = atom(
  (get) => {
    const sessionValue = get(reportBannerSessionAtom)
    const localValue = get(reportBannerLocalAtom)

    // 값의 타입이 두 가지인 경우는
    // 1. 세션 스토리지 및 로컬 스토리지에 저장된 값이 있는 경우는 boolean 또는 string 타입이다.
    // 2. setAtomValue를 통해 값을 변경할 때는 ReportBanner 타입의 객체를 전달한다.
    return {
      reportBannerShown:
        typeof sessionValue === 'boolean'
          ? sessionValue
          : (sessionValue as ReportBanner).reportBannerShown,
      healthInfoUpdateStatus:
        typeof localValue === 'string'
          ? localValue
          : (localValue as ReportBanner).healthInfoUpdateStatus,
    }
  },
  (get, set, newValue) => {
    set(reportBannerSessionAtom, newValue as ReportBanner)
    set(reportBannerLocalAtom, newValue as ReportBanner)
  },
)
