import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import {
  buttonLayoutStyle,
  buttonLayoutGapStyles,
  buttonSizeStyle,
  buttonStyleByVariant,
} from '@/v1/Button/styles'
import type { ButtonProps } from '@/v1/Button/types'

export { XLargeButton } from './XLargeButton'

/**
 * 버튼 컴포넌트
 * @param type 버튼 타입. 기본값은 'button'.
 * @param variant 버튼 스타일. 기본값은 'filled'.
 * @param size 버튼 크기. 기본값은 'large'. xsmall | small | medium | large
 * @param color 버튼 색상. 기본값은 'primary'. primary | secondary | tertiary
 * @param block 블록 레벨로 표시할지 여부. 기본값은 true. filled | outlined
 * @param leftIcon 버튼 왼쪽에 표시할 아이콘
 * @param rightIcon 버튼 오른쪽에 표시할 아이콘
 */
const Button = ({
  type = 'button',
  variant = 'filled',
  size = 'large',
  color = 'primary',
  block = true,
  leftIcon,
  rightIcon,
  disabled: isDisabled,
  children,
  className,
  ...restProps
}: PropsWithChildren<ButtonProps>) => {
  const buttonLayoutGapStyle =
    buttonLayoutGapStyles[
      [leftIcon, rightIcon].every((icon) => Boolean(icon)) ? 1 : 0
    ]

  return (
    <button
      type={type}
      className={clsx(
        buttonLayoutStyle,
        buttonLayoutGapStyle,
        buttonSizeStyle[size],
        'break-keep',
        { 'w-auto inline-flex': !block },
        { 'w-full flex': block },
        isDisabled
          ? buttonStyleByVariant[variant].disabled
          : buttonStyleByVariant[variant].color[color],
        className,
      )}
      disabled={isDisabled}
      {...restProps}
    >
      {leftIcon && <span className="ml-[-3px]">{leftIcon}</span>}
      <span>{children}</span>
      {rightIcon && <span className="mr-[-3px]">{rightIcon}</span>}
    </button>
  )
}

export default Button
