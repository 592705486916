import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import { INITIAL_TOKENS, TOKENS_KEY } from '@/consts'
import { getLocalStorage, setLocalStorage } from '@/utils'
import type { Tokens } from '@/utils/auth'

const storage = createJSONStorage<Tokens>(() => localStorage)

export const tokensAtom = atomWithStorage(TOKENS_KEY, INITIAL_TOKENS, {
  ...storage,
  getItem: (key, initialValue) => {
    return getLocalStorage(key, initialValue)
  },
  setItem: (key, newValue) => {
    return setLocalStorage(key, newValue)
  },
})
