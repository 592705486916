import { twMerge } from 'tailwind-merge'
import Menu from '@/assets/icons/v1/menu.svg'
import type { IconProps } from '@/v1/Icons/types'

const MenuIcon = ({ size = 20, className = '', ...restProps }: IconProps) => {
  return (
    <Menu
      width={size}
      height={size}
      className={twMerge('fill-black', className)}
      {...restProps}
    />
  )
}

export default MenuIcon
