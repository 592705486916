import FillPhone from '@/assets/icons/fillPhone.svg'
import { SvgProps } from '@/types'

const FillPhoneIcon = ({
  alt = 'phone',
  className = '',
  width = 12,
  height = 12,
  ...rest
}: SvgProps) => {
  return (
    <i aria-label={alt}>
      <FillPhone
        width={width}
        height={height}
        className={`fill-gray-100 ${className}`}
        {...rest}
      />
    </i>
  )
}

export default FillPhoneIcon
