import type { ButtonSizeStyle } from '@/v1/Button/types'

export const buttonLayoutStyle =
  'justify-center items-center flex-wrap cursor-pointer text-center focus:outline focus:outline-offset-2'
export const buttonLayoutGapStyles = ['gap-[4px]', 'gap-[8px]']

export const buttonSizeStyle: ButtonSizeStyle = {
  large: `min-w-[80px] px-4 h-[56px] py-2 rounded-[16px] text-[18px] [&_svg]:w-[24px] [&_svg]:h-[24px] font-medium prose-p1`,
  medium: `min-w-[80px] px-4 h-[44px] py-2 rounded-[12px] text-[16px] [&_svg]:w-[20px] [&_svg]:h-[20px] font-medium prose-p2`,
  small: `min-w-[60px] px-3 h-[36px] pt-[7px] pb-2 rounded-[8px] text-[14px] [&_svg]:w-[16px] [&_svg]:h-[16px] font-medium prose-p3`,
  xsmall: `min-w-[60px] px-3 h-[32px] rounded-[8px] text-[14px] [&_svg]:w-[16px] [&_svg]:h-[16px] font-medium prose-p3`,
}

export const buttonStyleByVariant = {
  filled: {
    color: {
      primary: [
        'text-white',
        'border-[1px]',
        'border-lavendar-500',
        'bg-lavendar-500',
        'hover:bg-lavendar-700',
        'active:bg-lavendar-700',
        'hover:border-lavendar-700',
        'active:border-lavendar-700',
        '[&_svg]:fill-white',
      ].join(' '),
      secondary: [
        'text-lavendar-500',
        'bg-grey-100',
        'hover:text-lavendar-700',
        'active:text-lavendar-700',
        'hover:bg-lavendar-100',
        'active:bg-lavendar-100',
        '[&_svg]:fill-lavendar-500',
      ].join(' '),
      tertiary: '',
    },
    disabled:
      'text-grey-400 bg-grey-100 [&_svg]:fill-grey-400 disabled:cursor-not-allowed',
  },
  outlined: {
    color: {
      primary: [
        'text-lavendar-500',
        'bg-white border-[1px]',
        'border-lavendar-500',
        'hover:text-lavendar-700',
        'active:text-lavendar-700',
        'hover:border-lavendar-700',
        'active:border-lavendar-700',
        '[&_svg]:fill-lavendar-500',
        '[&_svg]:hover:fill-lavendar-700',
        '[&_svg]:active:fill-lavendar-700',
      ].join(' '),
      secondary: [
        'text-grey-900',
        'bg-white border-[1px]',
        'border-grey-700',
        'hover:text-black',
        'active:text-black',
        'hover:border-black',
        'active:border-black',
        '[&_svg]:fill-grey-700',
        '[&_svg]:hover:fill-black',
        '[&_svg]:active:fill-black',
      ].join(' '),
      tertiary: [
        'text-grey-900',
        'bg-white border-[1px]',
        'border-grey-200',
        'font-bold',
        'hover:bg-lavendar-100',
        'active:bg-lavendar-100',
        'hover:text-lavendar-700',
        'active:text-lavendar-700',
        'hover:border-lavendar-100',
        'active:border-lavendar-100',
        '[&_svg]:fill-lavendar-500',
        '[&_svg]:hoverfill-lavendar-700',
        '[&_svg]:active-lavendar-700',
      ].join(' '),
    },
    disabled:
      'text-grey-400 bg-white border-[1px] border-grey-400 [&_svg]:fill-grey-400 disabled:cursor-not-allowed',
  },
} as const

export const buttonTextUnderlineStyle = 'underline underline-offset-2'
