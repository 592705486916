/**
 * Kakao SDK initialize하는 helper.
 */
import { useEffect } from 'react'

const KAKAO_SDK_URL = 'https://developers.kakao.com/sdk/js/kakao.min.js'

declare global {
  interface Window {
    Kakao: any
  }
}

const onload = () => {
  const { Kakao } = window
  if (!Kakao) return
  Kakao.init(process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT_KEY ?? '')
  Kakao.isInitialized()
}

const useKakaoSdk = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = KAKAO_SDK_URL
    script.async = true
    script.onload = onload
    script.onerror = onerror
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

export default useKakaoSdk
