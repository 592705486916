import NaverBand from '@/assets/icons/v1/naverBand.svg'
import type { IconProps } from '@/v1/Icons/types'

const NaverBandIcon = ({
  size = 20,
  className = '',
  ...restProps
}: IconProps) => {
  return <NaverBand height={size} className={className} {...restProps} />
}

export default NaverBandIcon
