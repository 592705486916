import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { Header } from './Header'

export interface FullPageModalHeaderProps {
  title?: string
  back?: boolean
  close?: boolean
  onClose?: () => void
  onBack?: () => void
  gaCloseSelector?: string
  gaBackSelector?: string
}

// FullScreenModalHeader의 back은 stotybook의 UI 참고용으로 사용됩니다.
// 그러므로 back버튼에 대한 onBack, gaBackSelector는 따로 받지 않으며,
// 현재 FullScreenModalHeader에서 back=true로 사용되는 곳은 없습니다.
// 후에 back true가 공통적으로 쓰이는 케이스가 2-3개 이상이 된다면,그때 해당 기능(onBack)을 공통화 처리할 계획입니다
export const FullPageModalHeader = ({
  title,
  back = false,
  close = false,
  onClose,
  onBack,
  gaCloseSelector,
  gaBackSelector = 'header_back',
}: FullPageModalHeaderProps) => {
  return (
    <Header>
      {back && (
        <Header.BackIconButton
          data-ga={gaBackSelector}
          className={clsx('absolute', 'top-0', 'left-0')}
          onBack={onBack}
        />
      )}
      {title && <Header.Title text={title} />}
      {close && (
        <Header.IconButton
          {...(gaCloseSelector ? { 'data-ga': gaCloseSelector } : {})}
          icon={<MaterialSymbol name="close" />}
          className={clsx('absolute', 'top-0', 'right-0')}
          onClick={onClose}
        />
      )}
    </Header>
  )
}
